import { DBFactory } from "~/classes/database/db_factory";
import { Assignment, Assignments } from "./assignment.model";
import { catchError, map } from "rxjs";
import {
  AssignmentResource,
  AssignmentResources,
} from "./assignment-resource.model";

export class AssignmentsService {
  static get = async (id: string) => {
    const db = DBFactory.createDatabase();

    const databaseData = await db.get({
      collection: "assignments",
      path: `assignments/${id}`,
    });

    return Assignment.fromMap(databaseData);
  };

  static streamAssignment = (id: string) => {
    const db = DBFactory.createDatabase();

    return db
      .streamRecord({
        collection: "assignments",
        path: `assignments/${id}`,
      })
      .pipe(
        map((databaseData) => {
          return Assignment.fromMap(databaseData);
        }),
        catchError((error) => {
          return [];
        })
      );
  };

  static streamAssignmentResources = (
    classroomId: string,
    assignmentId: string,
    userId?: string
  ) => {
    const db = DBFactory.createDatabase();

    const query = [
      {
        type: "where",
        field: "classroomId",
        operator: "==",
        value: classroomId,
      },
    ] as ModelQueryConfig;

    if (userId) {
      query.push({
        type: "where",
        field: "userId",
        operator: "==",
        value: userId,
      });
    }

    return db
      .streamList(
        {
          collection: `assignments/${assignmentId}/resources`,
          path: `assignments/${assignmentId}/resources`,
        },
        query
      )
      .pipe(
        map((databaseData) => {
          return databaseData.map((data) => AssignmentResource.fromMap(data));
        }),
        catchError((error) => {
          return [];
        })
      );
  };

  static listAssignments = async (classroomId: string, userId: string) => {
    const db = DBFactory.createDatabase();

    const databaseData = await db.list(
      {
        collection: `/assignments`,
        path: `/assignments`,
      },
      this.buildClassroomAssignmentsQuery(classroomId, userId)
    );

    return databaseData.map((data) => {
      return Assignment.fromMap(data);
    });
  };

  static fetchAllClassroomAssignmentResources = async ({
    userId,
    classroomId,
  }: {
    userId: string;
    classroomId: string;
  }): Promise<AssignmentResources> => {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collectionGroup: "resources",
        collection: "resources",
        path: `resources`,
      },
      [
        {
          type: "where",
          field: "classroomId",
          operator: "==",
          value: classroomId,
        },
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    return data.map((resource: any) => {
      return AssignmentResource.fromMap(resource);
    });
  };

  static listUserAssignments = async (userId: string) => {
    const db = DBFactory.createDatabase();

    const databaseData = await db.list(
      {
        collection: `/assignments`,
        path: `/assignments`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    return databaseData.map((data) => {
      return Assignment.fromMap(data);
    });
  };

  static streamAssignments(classroomId: string, userId: string) {
    const db = DBFactory.createDatabase();

    return db
      .streamList(
        {
          collection: `/assignments`,
          path: `/assignments`,
        },
        this.buildClassroomAssignmentsQuery(classroomId, userId)
      )
      .pipe(
        map((databaseData) => {
          return databaseData
            .map((data) => Assignment.fromMap(data))
            .filter((assignment) => assignment.isArchived != true);
        })
      );
  }

  static streamStudentClassroomAssignments(classroomId: string) {
    const db = DBFactory.createDatabase();

    return db
      .streamList(
        {
          collection: `/assignments`,
          path: `/assignments`,
        },
        [
          {
            type: "where",
            field: "classroomId",
            operator: "==",
            value: classroomId,
          },
        ]
      )
      .pipe(
        map((databaseData) => {
          return databaseData.map((data) => Assignment.fromMap(data));
        })
      );
  }

  static streamAllActiveAssignments(userId: string) {
    const db = DBFactory.createDatabase();

    return db
      .streamList(
        {
          collection: `/assignments`,
          path: `/assignments`,
        },
        [
          {
            type: "where",
            field: "userId",
            operator: "==",
            value: userId,
          },
          {
            type: "where",
            field: "dueTimestamp",
            operator: ">=",
            value: useFirstTimestampOfWeek(),
          },
          {
            type: "where",
            field: "dueTimestamp",
            operator: "<=",
            value: useLastTimestampOfWeek(),
          },
        ]
      )
      .pipe(
        map((databaseData) => {
          return databaseData.map((data) => Assignment.fromMap(data));
        })
      );
  }

  static buildClassroomAssignmentsQuery(classroomId: string, userId: string) {
    return [
      {
        type: "where",
        field: "userId",
        operator: "==",
        value: userId,
      },
      {
        type: "where",
        field: "classroomId",
        operator: "==",
        value: classroomId,
      },
    ] as ModelQueryConfig;
  }

  static saveAssignments = async (assignments: Assignments) => {
    const db = DBFactory.createDatabase();

    db.batchUpdate(assignments);
  };

  static deleteAssignments = async (assignments: Assignments) => {
    const db = DBFactory.createDatabase();

    db.batchDelete(assignments);
  };
}
